.admin-onboarding-container {
  width: 100%;
  min-height: 100vh;
  background: #ebebeb;
  .admin-top-nav {
    position: static;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #162b42;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;

    .admin-top-nav-logo {
      width: 150px;
      height: 40px;
      @media (max-width: 500px) {
        content: url("../../../../public/Images/quicketsolutionslogophone.svg");
        width: 60px;
        height: 40px;
      }
    }

    .admin-top-nav-navbar-text {
      margin-left: -1.7rem;
      font-size: 18px;
      font-weight: bold;
      @include typography-header(500, 12px, white);
    }

    .logoutbutton {
      @include Applynoborder();
      background: #2957a3;
      width: 70px;
      height: 30px;
      text-transform: capitalize;
      @include typography-header(500, 10px, white);
      margin-right: 10px;
    }
  }

  .admin-layout-container {
    width: 100%;
    min-height: calc(100vh - 40px);
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .Sidebar-admin {
      margin: 0;
      width: 200px;
      background: white;
      transition: all 0.27s linear;
      display: flex;
      flex-direction: column;
      .List-Sidebar-admin {
        display: flex;
        flex-direction: column;
        background: white;
        width: 100%;
        .open-menu-toggle {
          display: flex;
          justify-content: flex-end;
        }
        .seprator {
          @include addverticalline(100%, 1px);
        }
        .actions {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          gap: 8px;
          margin-top: 10px;

          .paper-searchbox {
            @include Applynoborder;
            padding: 2px 4px;
            display: flex;
            align-items: center;
            width: 90%;
            height: $tenant-table-control-height;
            background: #f7f7f7;
            border: 1px solid $primary-font-color;

            .search-button {
              padding: 5px;
              color: #2957a3;
            }

            .search-input {
              margin-left: 1;
              flex: 1;
              @include typography-header(500, 12px, black);
            }
          }
          .add-integration-button {
            background: #2957a3;
            width: 90%;
            height: 35px;
            text-transform: capitalize;
            @include typography-header(500, 10px, white);
          }
          .line {
            @include addverticalline;
          }
        }

        .no-error-message {
          word-break: break-all;
          @include typography-header(500, 12px, red);
        }
        .selectedItem {
          background: #d4dded;
          @include typography-header(600, 12px, $primary-font-color);
        }
        .unselectedItem {
          background: #efefef;
          @include typography-header(600, 12px, black);
        }
        .errorbordernone {
          border: 2px solid #ebd39c;
        }
        .errorborder {
          border: none;
        }

        .menu-1 {
          @include layout(row, space-around);
          align-items: center;
          width: 90%;
          height: 35px;
          border-radius: 7px;
          text-transform: capitalize;
          cursor: pointer;

          .iconapp {
            width: 14px;
            height: 14px;
          }
          .text {
            width: 60%;
            text-align: left;
            @include truncate-text(100px);
          }
          .unsaved-icon {
            position: absolute;
            margin-top: -30px;
            margin-right: -170px;
            color: #eec054;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .collapsed-admin {
      margin: 0;
      width: 80px;
      .main-tab {
        .List-Sidebar-admin {
          .actions {
            .add-integration-button {
              @include Applynoborder;
              .button-text {
                display: none;
              }
            }
            .menu-1 {
              .text {
                display: none;
              }
              .unsaved-icon {
                position: absolute;
                margin-top: -30px;
                margin-right: -60px;
                color: #eec054;
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }
    .collapsed-layout {
      width: calc(100% - 80px);
      display: flex;
      flex-direction: row;
      align-items: stretch;
      gap: 10px;
      padding: 10px;
      transition: all 0.27s linear;
      @include lg {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .cardone-io {
        background: #fff;
        border-radius: 6px;
        padding: 12px;
        height: 100%;
        width: 40%;
        @include lg {
          width: 100%;
        }
      }
      .cardtwo-to {
        background: #fff;
        border-radius: 6px;
        padding: 12px;
        height: 100%;
        width: 59%;
        @include lg {
          width: 100%;
        }
      }
    }
    .sidebar-rest-admin {
      width: calc(100% - 200px);
      display: flex;
      flex-direction: row;
      align-items: stretch;
      gap: 10px;
      padding: 10px;
      @include lg {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .cardone-io {
        background: #fff;
        border-radius: 6px;
        padding: 12px;
        height: 100%;
        width: 40%;
        @include lg {
          width: 100%;
        }
      }
      .cardtwo-to {
        background: #fff;
        border-radius: 6px;
        padding: 12px;
        height: 100%;
        width: 59%;
        @include lg {
          width: 100%;
        }
      }
    }
  }
}
