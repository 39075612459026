.top-navbar-payment {
  position: static;
  top: 0;
  left: 0;
  // width: 100vw;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $navbar-height;

  .logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 40px;
    @media (max-width: 500px) {
      content: url("../../../../public/Images/quicketsolutionslogophone.svg");
      width: 60px;
      height: 40px;
    }
  }

  .navbar-text {
    font-size: 18px;
    font-weight: bold;
    @include typography-header(500, $navbar-text-size, $primary-font-color);
  }
}
