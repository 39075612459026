  .load-1 {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      padding: 20px 20px;
      text-align: center;
      background-color: #2957A3;
  }


  .load-wrapp:last-child {
      margin-right: 0;
  }

  .line {
      display: inline-block;
      width: 10px;
      height: 15px;
      border-radius: 8px;
      background-color: white;
      margin-right: 5px;


  }

  .load-1 .line:nth-last-child(1) {
      animation: loadingA 1.5s 1s infinite;
  }

  .load-1 .line:nth-last-child(2) {
      animation: loadingA 1.5s 0.5s infinite;
  }

  .load-1 .line:nth-last-child(3) {
      animation: loadingA 1.5s 0s infinite;
  }

  @keyframes loadingA {
      0% {
          height: 15px;
      }

      50% {
          height: 45px;
      }

      100% {
          height: 15px;
      }
  }