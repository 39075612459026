.reader-one {
  width: 210px;
  padding: 8px;
  border-radius: 7px;
  background: #ebf1f8;
  .reader-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 0px;
    margin-top: -10px;
    margin-right: 0px;
    .close-reader {
      color: $primary-font-color;
    }
  }
  .reader-content {
    @include layout(column, flex-start);
    align-items: flex-start;
    .error-message {
      margin-left: 2px;
      margin-right: 2px;
      @include typography-header(500, 10px, red);
      text-transform: capitalize;
      word-break: break-all;
    }
    .reader-name {
      @include typography-header(600, 13px, #1f2937);
      @include truncate-text(170px);
    }
    .reader-id {
      @include truncate-text(190px);
      @include typography-header(500, 11px, #1f2937);
    }
    .process-status {
      margin-top: 5px;
      display: flex;
      align-items: center;
      @include typography-header(500, 11px, black);
      .icon-null {
        width: 16px;
        height: 16px;
        color: $primary-font-color;
      }
      .icon-true {
        width: 16px;
        height: 16px;
        color: green;
      }
      .icon-false {
        width: 16px;
        height: 16px;
        color: red;
      }
    }
    .config-div {
      display: flex;
      align-items: center;
      width: 100%;
      .jira-select {
        width: 100%;
        height: 30px;
        padding: 5px;
        @include typography-header(500, 12px, gray);
        // color: #172b4d;
        border-radius: 4px;
        @include Applynoborder();
        background: white;
        margin-bottom: 6px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        option[value=""][disabled] {
          display: none;
        }
      }
      .config-label {
        @include typography-header(500, 12px, #1f2937);
      }
    }
  }
}
