$primary-background: #ffffff;
$secondary-background: #6da8f5;
$tertiary-background: #eff6fb;

$primary-font-color: #2957a3;
$secondary-font-color: #eff6fb;
$tertiary-font-color: #000000;

$button-bg-color-main: #6da8f5;
$button-font-color-main: #eff6fb;
$button-hover-color: #aacaf3;

$successful-payment-bg: #e9fbf0;
$successful-icon: #38a75f;
$payment-status-height: 280px;
$failed-icon: #f94d4d;
$failed-payment-bg: #ffecec;
$pending-bg: #f0efe5;

$button-application-font: 12px;

// 1366 styling

$quicket-logo-width: 160px;
$quicket-logo-height: 40px;
$navbar-height: 40px;
$navbar-width: 100%;
$navbar-text-size: 16px;

$margin-top-restpage: 20px;
$margin-between-sidebar-payment: 1rem;

$Sidebar-width-np: 200px;
$Button-width-sidebar: 150px;
$Button-height-sidebar: 45px;
$Button-padding: 8px;
$gap-between-sidebar-controls: 10px;
$Sidebar-font-size: 18px;
$Button-font-size-sidebar: 14px;
$icon-width-sidebar: 18px;
$icon-height-sidebar: 18px;

$payment-order-width: 400px;
$parent-card-title-height: 100px;
$payment-sub-card-width: 330px;
$payment-sub-card-height: 120px;
$payment-sub-card-padding: 10px;
$payment-sub-card-div-padding: 10px;
$difference-sub-main-top: -50px;

$reference-id-font-size: 14px;
$order-details-font-size: 12px;
$order-details-margin-bottom: 10px;
$order-details-gap: 2rem;

$order-last-card-width: 180px;
$order-last-card-height: 30px;
$order-last-card-font-size: 13px;
$order-last-card-p-font-size: 10px;
$order-last-card-top: 100px;
$order-last-card-left: 100px;

$main-card-stripe-padding: 7px;
$main-card-stripe-margin-top: 25px;
$status-height: 239px;
$status-padding: 10px;
$status-border-radius: 20px;

$status-icon-size: 50px;
$status-text-main: 14px;
$amount-text-main: 26px;
$amount-margin-top: 13px;
$transaction-id-text: 13px;
$transaction-margin-top: 20px;
$date-text-size: 11px;
$button-application-font: 14px;
