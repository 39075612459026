@import "./variables";


@mixin layout($flexdirection, $justifycontent) {
    display: flex;
    flex-direction: $flexdirection;
    justify-content: $justifycontent;
    align-items: center;

    @if $flexdirection ==row {
        >*+* {
            margin-left: 10px;
        }
    }

    @else if $flexdirection ==column {
        margin-left: 5px;
        margin-right: 5px;
    }

}


@mixin row-layout-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    >*+* {
        margin-left: 10px;
    }
}

@mixin column-layout-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



@mixin typography-header($font-weight, $font-size, $font-color) {
    font-family: 'Poppins';
    font-weight: $font-weight;
    font-size: $font-size;
    color: $font-color;

}




@mixin Applynoborder {
    box-shadow: none;
    border: none;
}


@mixin icon-paymentmixin($colorabc){
    color: $colorabc;
    height: $status-icon-size;
    width: $status-icon-size;
}
@mixin status-textmixin($colorabc){
    color: $colorabc;
    font-size: $status-text-main;
    font-weight: 600;
    font-family: 'Poppins';
    text-transform: capitalize;
    text-align: center;
}
@mixin amount-textmixin($colorabc){
    margin-top: $amount-margin-top;
    color: $colorabc;
    font-size: $amount-text-main;
    font-weight: 600;
    font-family: 'Poppins'; 
    text-align: center;
    text-transform: uppercase;
}

@mixin transaction-id-textmixin($colorabc){
    margin-top: $transaction-margin-top;
    color: $colorabc;
    font-size: $transaction-id-text;
    font-weight: 600;
    font-family: 'Poppins'; 
}

@mixin date-textmixin($colorabc){
    color: $colorabc;
    font-size: $date-text-size;
    font-family: 'Poppins'; 
    font-weight: 600;
}

@mixin searchresultlabel($width, $height, $background-color, $color) {
    width: $width;
    height: $height;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 5px;
    background-color: $background-color;
    text-transform: capitalize;
    @include typography-header(200, 12px,$color );
    @include Applynoborder;
    
  }
  
  @mixin sm {
    @media only screen and (max-width: 640px) {
        @content;
    }
}
@mixin md {
    @media only screen and (max-width: 768px) {
        @content;
    }
}
@mixin lg {
    @media only screen and (max-width: 1024px) {
        @content;
    }
}
@mixin xl {
    @media only screen and (max-width: 1280px) {
        @content;
    }
}
@mixin xxl {
    @media only screen and (max-width: 1536px) {
        @content;
    }
}