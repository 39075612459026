.alertModal-admin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: white;
  border-radius: 1px;
  box-shadow: 24px;
  padding: 1rem;
  .alertText {
    @include typography-header(400, 16px, rgba(0, 0, 0, 0.8));
    border-bottom: 1px rgba(0, 0, 0, 0.3) solid;
    padding: 1rem;
  }
  .alertModal-button {
    padding: 1rem;
    display: flex;
    gap: 1px;
    justify-content: flex-end;
    .ok-button {
      text-transform: none;
      background: #1a4ea3;
      @include typography-header(400, 14px, #fff);
    }
    .ok-button:hover {
      background: #106ce7;
    }
  }
}
