.cancel-button,
.process-payment-button,
.continue,
.download-reciept-button,
.recheck-button,
.retry-button {
  height: 33px;
  width: 100%;
  background-color: $button-bg-color-main;
  border-radius: 10px;
  text-align: center;
  @include typography-header(
    500,
    $button-application-font,
    $button-font-color-main
  );
  text-transform: none;

  &:hover {
    font-weight: 500;
    background-color: $primary-font-color;
    color: $secondary-font-color;
    @include Applynoborder;
  }

  &:disabled {
    opacity: 60%;
    font-weight: 300;
    color: $secondary-font-color;
    @include Applynoborder;
  }

  @include Applynoborder;
}


.cancel-button{
  width: 180px;
  @include typography-header(
    100,
    $button-application-font,
    $button-font-color-main
  );
}

.button-container-recheck{
  width: 250px;
  height: 40px;
  margin-bottom: 20px;
  .cancel-button{
    width: 250px;
  }
}


.preview-pay-button,
.confirm-payment-button {
  @extend .retry-button;
  width: 160px;
}

.preview-pay-button {
  margin-right: 4px;
}

.dialog-div {
  padding: 10px;
  width: 450px;
  @include layout(row, space-between);
  @include sm {
    padding: 10px;
    width: 300px;
    @include layout(row, space-between);
  }

  .close-modal {
    position: absolute;
    top: 15px;
    right: 5px;
    @include typography-header(
      500,
      $button-application-font,
      $button-font-color-main
    );
    text-transform: capitalize;

    &:hover {
      background-color: $button-hover-color;
      color: $button-font-color-main;
    }
  }
}

.Rest-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.status {
  margin-top: 20px;
}

.new-button {
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 12px;
  .row-button {
    width: $payment-sub-card-width;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    // margin-top: 15px;
    // margin-bottom: 10px;
  }
  .cancel-div{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.main-button-section {
  width: $payment-sub-card-width;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 12px;
  margin-bottom: 18px;

  .download-text {
    @include date-textmixin($tertiary-font-color);
    font-size: 11px;
    margin-top: 20px;
    text-align: center;
  }

  .clickable-text {
    color: blue;
    font-size: 11px;
    margin-top: 20px;
    text-align: center;
    text-decoration: underline;
  }

  .cancel-div{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.payment-status-pending {
  background-color: $pending-bg;
  width: $payment-sub-card-width;
  margin-top: $main-card-stripe-margin-top;
  // min-height: $status-height;
  max-height: fit-content;
  @include Applynoborder;
  padding: $status-padding;
  border-radius: $status-border-radius - 5;
  display: flex;
  flex-direction: column;
  align-items: center;

  .status-text {
    @include status-textmixin($primary-font-color);
  }

  .subtitle-text {
    text-align: center;
    @include typography-header(500, 12px, black);
  }

  .amount-text {
    @include amount-textmixin($tertiary-font-color);
  }

  .transaction-id-text {
    text-align: center;
    @include typography-header(500, 12px, black);
    margin-top: $transaction-margin-top;
  }

  .date-text {
    text-align: center;
    margin-top: $transaction-margin-top;
    @include date-textmixin($tertiary-font-color);
  }
}

.payment-status-successful {
  background-color: $successful-payment-bg;
  width: $payment-sub-card-width;
  margin-top: $main-card-stripe-margin-top;
  // min-height: $status-height;
  max-height: fit-content;
  @include Applynoborder;
  padding: $status-padding;
  border-radius: $status-border-radius - 5;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon-payment {
    @include icon-paymentmixin($successful-icon);
  }

  .status-text {
    @include status-textmixin($primary-font-color);
  }

  .amount-text {
    @include amount-textmixin($tertiary-font-color);
  }

  .transaction-id-text {
    text-align: center;
    @include typography-header(500, 12px, black);
    margin-top: $transaction-margin-top;
  }

  .date-text {
    text-align: center;
    margin-top: $transaction-margin-top;
    @include date-textmixin($tertiary-font-color);
  }
}

.payment-status-failure {
  background-color: $failed-payment-bg;
  // min-height: $status-height;
  max-height: fit-content;
  width: $payment-sub-card-width;
  margin-top: $main-card-stripe-margin-top;
  @include Applynoborder;
  padding: $status-padding;
  @include Applynoborder;
  padding: $status-padding;
  border-radius: $status-border-radius - 5;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon-payment {
    @include icon-paymentmixin($failed-icon);
  }

  .status-text {
    @include status-textmixin($primary-font-color);
  }

  .amount-text {
    @include amount-textmixin($tertiary-font-color);
  }

  .transaction-id-text {
    text-align: center;
    @include typography-header(500, 12px, black);
    margin-top: $transaction-margin-top;
  }

  .date-text {
    text-align: center;
    margin-top: $transaction-margin-top;
    @include date-textmixin($tertiary-font-color);
  }
}

.container-stripe-or {
  display: flex;
  justify-content: center;
}

.pos-card-main {
  background-color: $tertiary-background;
  width: $payment-sub-card-width;
  margin-top: $main-card-stripe-margin-top;
  @include Applynoborder;
  border-radius: $status-border-radius - 10;
}

.error-div-main {
  width: $payment-sub-card-width;
  margin-top: 5px;
  @include Applynoborder;
  padding: 0;
  @include typography-header(500, 10px, red);
}

.stripe-payment-card-main {
  background-color: $tertiary-background;
  width: $payment-sub-card-width;
  margin-top: $main-card-stripe-margin-top;

  @include Applynoborder;
  padding: $status-padding;

  .dropin-container-div {
    width: calc(($payment-sub-card-width - $status-padding) - 10px);

    .braintree-dropin {
      font-family:"Roboto", "Helvetica", "Arial", sans-serif;
    }

    .braintree-form__field-group{
      margin-bottom: 12px;
    }

    .braintree-sheet {
      border: 0;
    }

    // .braintree-heading {
    //   display: none;
    //   margin-bottom: 0;
    // }

    .braintree-placeholder{
      display: none;
      margin-bottom: 0;
    }

    // .braintree-sheet__header{
    //   display: none;
    //   margin-bottom: 0;
    // }

    // To hide the heading - Pay By Card
    .braintree-sheet__header-label{
      display: none;
      margin-bottom: 0;
    }

    // To hide the Privacy Policy Notice
    .braintree-form__notice-of-collection{
      display: none;
      margin-bottom: 0;
    }

    .braintree-sheet__header{
      padding: 8px 0 0 4px;
    }
  }
}
