.Main-status-payload-page {
    width: 100vw;
    height: 100vh;
    background: #F8F8F8;

    .c-card {

        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 450px;
        height: fit-content;
        border-radius: 10px;
        background-color: white;
        @include Applynoborder;
        padding: 10px;

        .c-card-header {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 150px;

            .c-card-header-title {
                @include typography-header(600, 19px, $primary-font-color )
            }
        }

        .c-border-section {
            position: relative;
            width: 100%;
            height: 30px;

            .c-border-left,
            .c-border-right {
                position: absolute;
                top: 0;
                width: 30px;
                height: 100%;
                border-radius: 50%;
                background-color: #F8F8F8;
                @include Applynoborder;
            }

            .c-border-left {
                left: -20px;
            }

            .c-border-right {
                right: -20px;
            }

            .c-dotted-line {
                position: absolute;
                top: 0;
                width: 90%;
                border: none;
                border-bottom: 3px dotted #a5a5a575;
                margin-left: 16px;
                margin-top: 15px;
            }

        }

     
        .content-body {
            margin-top: 2rem;
    
            @include column-layout-container;
            gap: 1rem;

            .one-data {
                @include row-layout-container;
                .title-onedata{
                    text-align: left;
                    @include typography-header(200, 13px, $primary-font-color )
                }
                .response-onedata{
                    text-align: right;
                    @include typography-header(600, 13px, black);
 
                }
            }
        }




    }

}