.LocationTable {
  width: 100%;
  padding: 10px;
  @include Applynoborder();
  .LocationHeader {
    @include layout(row, space-between);

    .LocationHeaderText {
      @include typography-header(500, 16px, #1f2937);
    }

    .LocationTableActions {
      margin-top: 5px;
      @include layout(row, flex-end);
      .paper-searchbox {
        @include Applynoborder;
        padding: 2px 4px;
        display: flex;
        align-items: center;
        width: 150px;
        border-radius: 5px;
        height: 35px;
        background: #f7f7f7;
        border: 1px solid #ececec;

        .search-button {
          padding: 5px;
          color: 2957A3;
        }

        .search-input {
          margin-left: 1;
          flex: 1;
          @include typography-header(500, 12px, black);

          @include md {
            @include typography-header(500, 12px, black);
          }
        }
      }

      .add-location-button {
        color: #2957a3;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    .save-location-button {
      margin-top: 8px;
      @include Applynoborder();
      background: #46c186;
      width: 150px;
      height: 35px;
      text-transform: capitalize;
      @include typography-header(500, 12px, white);
    }
  }

  .add-location-pagination {
    // margin-top: $tenant-table-margin-top;
    @include layout(row, center);

    .custom-pagination-location {
      @include custom-pagination-style;
    }
  }

  .error-location-table {
    width: 100%;
    height: 100%;
    .no-location-message {
      text-align: left;
      @include typography-header(500, 12px, grey);
    }
  }

  .Location-Card-Table {
    margin-top: $tenant-table-margin-top;
    @include column-layout-container;
    gap: 0.7rem;
    .add-border {
      border-right: 0.5px rgba(41, 87, 163, 0.2) solid;
      border-bottom: 0.5px rgba(41, 87, 163, 0.2) solid;
      border-left: 0.5px rgba(41, 87, 163, 0.2) solid;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    .main-accordion {
      width: 100%;
      .override-button {
        outline: none;
        @include override-button-mui;
        cursor: pointer;
        width: 100%;
        .unsaved-changes {
          background: #f7f7f7;
          border: 2px solid #ebd39c;
        }
        .normal {
          border: 1px solid #ececec;
          background: #f7f7f7;
        }

        .Location-One-Info {
          width: 100%;
          height: 40px;
          border-radius: 6px;

          @include layout(row, flex-start);
          padding: $padding-s;

          .Location-name-div {
            width: 20%;
            margin-right: 5px;

            .Location-name {
              text-align: left;
              @include typography-header(500, 13px, #353434);
              @include truncate-text(150px);
            }
          }
          .Location-adr-div {
            width: 68%;

            .Location-adr {
              text-align: left;
              @include typography-header(500, 13px, #353434);
              @include xl {
                @include truncate-text(350px);
              }
              @include md {
                @include truncate-text(250px);
              }
              @include sm {
                @include truncate-text(150px);
              }
            }
          }

          .Location-action-buttons {
            width: 10%;
            text-align: right;
            display: flex;
            justify-content: flex-end;
            padding: 0;
            .unsaved-icon {
              margin-top: 8px;
              margin-right: 7px;
              color: #eec054;
              // color: $primary-font-color;
            }
            .down-reader {
              color: $primary-font-color;
            }
          }
        }
      }
    }
  }
}
