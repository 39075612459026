.Sidebar {
  width: $payment-order-width;


  @include Applynoborder;
  margin-top: $margin-top-restpage;

  .Sidebar-container-data {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 7px 7px 0px 0px;
    overflow: hidden;
    // gap: $gap-between-sidebar-controls;


    .Sidebar-controls {
      width: 100%;
      height: $Button-height-sidebar;
      border-radius:0px;
      @include layout(row, center);
      gap: 10px;
      align-items: center;
      padding: $Button-padding;
      cursor: pointer;
     
      .sidebar-button-text {
        margin-left: 0px;
        margin-right: 0px;
        @include typography-header(500, $Button-font-size-sidebar, $primary-font-color); 
      }

      .selected{
        @include typography-header(500, 16px, #EFF6FB);
      }

      .icon-sidebar {

        width: $icon-width-sidebar;
        height: $icon-height-sidebar;
        // color: $primary-font-color;
      }
    }


  }





}