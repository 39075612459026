.auth-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 100vh;
    .login-container {
      width: 30rem;
      border: 2px solid #c8c8c8;
      background: #fafafa;
      border-radius: 3%;
      .login-form-container {
        margin: 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
      
        .forget-pass{
          color: #4D5B7C;
          text-align: center;
          justify-content: center;
        }
        .heading-pass{
          color: #4D5B7C;
          text-align: center;
          justify-content: center;
        }
      }
    }
    .link-url {
      color: #2957a3;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    
    
  }
  
  