.main-transition-div {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    gap: 0.5rem;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .application-loading-ty {
        @include typography-header(500, 1.3rem, rgba(0, 0, 0, 0.5));
        // margin-bottom: 1rem;
        @include sm {
            @include typography-header(500, 14px, rgba(0, 0, 0, 0.5));
        }
    }

    .no-description{
        margin-bottom: 3rem;
    }

    .id-description{
        margin-bottom: 3rem;
        .transition-ref-id {
            @include typography-header(500, 1.1rem, rgba(0, 0, 0, 0.5));
            text-transform: capitalize;
            @include sm {
                @include typography-header(500, 12px, rgba(0, 0, 0, 0.5));
            }
            margin-bottom: 0.5rem;
        }
        
    }

    .continue {
        width: 300px;
        height: 30px;
        font-size: 1rem;
        text-transform: capitalize;
        font-weight: 500;
        background-color: $button-font-color-main;
        color: white;
        border-radius: 3;
        background-color: $button-bg-color-main;
        color: $button-font-color-main;

        @include sm {
            width: 250px;
            font-size: 16px;
            background-color: $button-bg-color-main;
            color: $button-font-color-main;

        }
    }

}


.animation-1 {
    $offset: 187;
    $duration: 1.4s;

    .spinner {
        animation: rotator $duration linear infinite;
    }

    @keyframes rotator {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(270deg);
        }
    }

    .path {
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation:
            dash $duration ease-in-out infinite,
            colors ($duration*4) ease-in-out infinite;
    }

    @keyframes colors {
        0% {
            stroke: #4285F4;
        }

        25% {
            stroke: #2957A3;
            ;
        }

        50% {
            stroke: #6DA8F5;
        }

        75% {
            stroke: #2957A3;
        }

        100% {
            stroke: #4285F4;
        }
    }

    @keyframes dash {
        0% {
            stroke-dashoffset: $offset;
        }

        50% {
            stroke-dashoffset: $offset/4;
            transform: rotate(135deg);
        }

        100% {
            stroke-dashoffset: $offset;
            transform: rotate(450deg);
        }
    }
}

.animation-2 {
    .square-image {
        width: 200px;
        height: 170px;
    }

}

.animation-3 {
    .square-image {
        width: 200px;
        height: 170px;
    }

}