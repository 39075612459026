.LocationForm {
  margin-top: -10px;
  padding: 10px;
  width: 100%;
  .divide-cols {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .lf-col1 {
      width: 95%;
    }
    .lf-col2 {
      width: 5%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
    .save-location-button {
      margin-top: 5px;
      @include Applynoborder();
      background: #46c186;
      width: 80px;
      height: 35px;
      text-transform: capitalize;
      @include typography-header(500, 12px, white);
    }
    .discard-tenant-button {
      margin-top: 5px;
      @include Applynoborder();
      background: #eba6a2;
      // border: 1px solid #dc362e;
      width: 80px;
      height: 35px;
      text-transform: capitalize;
      @include typography-header(500, 12px, white);
    }
  }
  .form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    .line-lf {
      margin-top: 5px;
      @include addverticalline(99%, 0.7px);
    }

    .form-field {
      flex: 1 1 200px;
      margin-right: 5px;
      label {
        @include typography-header(500, 10px, $primary-font-color);
        margin-bottom: 5px;
      }
      .no-payment-message {
        @include typography-header(500, 12px, red);
      }
      .payment-config {
        display: flex;
        align-items: center;
        width: 100%;
        .jira-select {
          width: 100%;
          height: 30px;
          padding: 5px;
          @include typography-header(500, 12px, gray);
          color: #172b4d;
          border-radius: 4px;
          @include Applynoborder();
          background: #f1f4f9;
          margin-bottom: 6px;
          transition: border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
          option[value=""][disabled] {
            display: none;
          }
        }
        .config-label {
          @include typography-header(500, 12px, #1f2937);
        }
      }
    }
    .form-fieldtype {
      flex: 1 1 200px;
      margin-right: 5px;
      &:nth-child(2) {
        flex: 2 2 400px;
      }
      label {
        @include typography-header(500, 10px, $primary-font-color);
        margin-bottom: 5px;
      }
      .no-payment-message {
        @include typography-header(500, 12px, red);
      }
    }
  }
}
