.Tenant-card {
  .Tenant-header {
    width: 100%;

    @include layout(row, space-between);
    .header-content {
      width: 85%;
      @include layout(row, flex-start);
      align-items: stretch;
      .error-message {
        word-break: break-all;
        @include typography-header(500, 11px, red);
      }
      .main {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      .MainText {
        @include typography-header(500, 14px, #333);
        height: 30px;
        display: flex;
        align-items: center;
      }
      .SubMainText {
        @include typography-header(500, 13px, rgba(31, 41, 55, 0.69));
        height: 30px;
        display: flex;
        align-items: center;
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 5px;
      margin-top: -30px;
      .edit-tenant-button {
        background: #2957a3;
        width: 80px;
        height: 35px;
        text-transform: capitalize;

        @include typography-header(500, 12px, white);
        .hide-text {
          @include sm {
            display: none;
          }
        }
      }
      .save-tenant-button {
        @include Applynoborder();
        background: #46c186;
        width: 80px;
        height: 35px;
        text-transform: capitalize;
        @include typography-header(500, 12px, white);
      }
      .save-tenant-button:disabled {
        background-color: #ccc;
        color: #888;
        cursor: not-allowed;
      }
      .discard-tenant-button {
        @include Applynoborder();
        background: #eba6a2;
        // border: 1px solid #dc362e;
        width: 80px;
        height: 35px;
        text-transform: capitalize;
        @include typography-header(500, 12px, white);
      }
    }
  }

  .payment-selector {
    margin-top: 10px;
    width: 100%;
    @include layout(column, flex-start);
    align-items: flex-start;
    gap: 10px;
    .payment-container {
      width: 100%;
      @include layout(row, space-between);
      align-items: flex-start;
      .braintree-config {
        @extend .stripe-config;
      }
      .stripe-config {
        width: 49%;
        padding: 8px;
        background: #efefef;
        border-radius: 5px;
        @include typography-header(500, 12px, black);
        .option-1 {
          margin-top: 8px;
          width: 100%;
          background: white;
          padding: 5px;
          border-radius: 6px;
          @include layout(row, flex-start);
          align-items: center;
        }
        .no-payment-message {
          @include typography-header(500, 12px, red);
        }
      }
    }
  }

  .to-line {
    width: 100%;
    background: #d9d9d9;
    height: 2px;
    margin-top: 5px;
  }
  // Custom styling for the accordion
  .custom-accordion {
    @include Applynoborder();
    &::before {
      content: none;
      @include Applynoborder();
    }

    .MuiAccordionSummary-root {
      padding: 10px;
      height: 35px;
      background-color: #efefef;
      border-radius: 7px;
      transition: background-color 0.3s;
      &.Mui-expanded {
        display: none;
      }
      .MuiTypography-root {
        @include typography-header(600, 14px, #646b75);
      }
    }

    // Style the content of the accordion
    .MuiAccordionDetails-root {
      padding: 0px; // Add padding to content
    }
  }
}
