.saved-modal {
  position: fixed;
  top: 50px;
  right: 20px;
  background-color: #ecf8e9;
  color: #4eb48b;
  padding-left: 10px;
  opacity: 1;
  transform: translateX(0);
  border: 1px solid #4eb48b;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: modal-appear 0.5s ease-in-out forwards;
  z-index: 1;
  .saved-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    color: #4eb48b;
  }
  .cancel-icon {
    width: 24px;
    height: 24px;
    color: #4eb48b;
  }

  .saved-message p {
    margin: 0;
    @include typography-header(500, 14px, #4eb48b);
  }
}
@keyframes modal-appear {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
