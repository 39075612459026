.Reader-Card-Form {
  margin-top: 10px;
  margin-left: 10px;
  width: 99%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  .divide-columns-rt {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .rt-col2 {
      width: 5%;
    }
    .rt-col1 {
      width: 95%;
      .container-reader-card {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: wrap;
        align-items: stretch;

        .no-reader-message {
          display: flex;
          align-items: center;
          text-align: left;
          @include typography-header(500, 12px, grey);
        }
        .add-reader-card-button {
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          .add-button {
            color: #2957a3;
          }
        }
      }
    }
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0px;
    .save-reader-button {
      margin-top: 5px;
      @include Applynoborder();
      background: #46c186;
      width: 80px;
      height: 30px;
      text-transform: capitalize;
      @include typography-header(500, 12px, white);
      margin-bottom: 0px;
    }
  }
  .form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    .line-lf {
      margin-top: 5px;
      @include addverticalline(99%, 0.7px);
    }
  }
}
