.tenant-table {
  width: 100%;
  .Tenant-header {
    @include layout(row, space-between);
    .Tenant-header-text {
      @include typography-header(600, 14px, #333);
    }

    .TenantTableActions {
      margin-top: 5px;
      @include layout(row, flex-end);
      .paper-searchbox {
        @include Applynoborder;
        padding: 2px 4px;
        display: flex;
        align-items: center;
        width: 150px;
        border-radius: 5px;
        height: 35px;
        background: #f7f7f7;
        border: 1px solid #ececec;

        .search-button {
          padding: 5px;
          color: 2957A3;
        }

        .search-input {
          margin-left: 1;
          flex: 1;
          @include typography-header(500, 12px, black);

          @include md {
            @include typography-header(500, 12px, black);
          }
        }
      }

      .add-tenant-button {
        color: #2957a3;
      }
    }
  }

  .add-tenant-pagination {
    @include layout(row, center);
    margin: 5px 0px 10px;
    .custom-pagination {
      @include custom-pagination-style;
    }
  }

  .error-tenant-table {
    width: 100%;
    height: 100%;
    .no-payment-message {
      text-align: left;
      @include typography-header(500, 12px, grey);
    }
  }

  .Tenant-Card-Table {
    margin-top: 5px;
    @include column-layout-container;
    gap: 0.5rem;

    .override-button-o {
      @include override-button-mui;
      width: 100%;
      cursor: pointer;
      .errorbordernone {
        width: 100%;
        height: 35px;
        border-radius: 6px;
        border: 2px solid #ebd39c;
      }
      .errorborder {
        width: 100%;
        height: 35px;
        border-radius: 6px;
        border: none;
      }

      .selectedTenant {
        background: #d4dded;
        color: $primary-font-color;
      }
      .unselectedTenant {
        background: #f7f7f7;
        color: #353434;
      }
      .Tenant-One-Info {
        width: 100%;
        height: 35px;
        @include layout(row, flex-start);
        padding: $padding-s;

        .Tenant-name-div {
          width: 500px;

          .Tenant-name {
            text-align: left;
            font-family: "Inter";
            font-weight: 600;
            font-size: 12px;
            @include truncate-text($tenant-name-div);
          }
        }

        .Tenant-code-div {
          width: 500px;

          .Tenant-code {
            text-align: left;
            font-family: "Inter";
            font-weight: 600;
            font-size: 12px;
            @include truncate-text($tenant-code-div);
          }
        }

        .Tenant-action-buttons {
          width: $tenant-action-div;
          display: flex;
          justify-content: flex-end;
          padding: 0;
          .unsaved-icon {
            margin-top: 6px;
            margin-right: 6px;
            color: #eec054;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
