.MainContainer-admin {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 10px;
  padding: 10px;
}

.jira-text-field {
  // display: inline-block;
  // margin-bottom: 2px;
  // margin-right: 10px;
  width: 100%;
  height: 28px;
  padding: 8px 12px;
  color: #172b4d;
  border-radius: 4px;
  @include Applynoborder();
  // border: 1px solid #dfe1e6;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  @include typography-header(500, 13px, #172b4d);
}

.inputbggrey {
  background: #f1f4f9;
}
.inputbggrey-margin {
  background: #f1f4f9;
  margin-bottom: 6px;
}

.inputbgwhite-margin {
  background: white;
  margin-bottom: 6px;
}

.inputbgwhite {
  background: white;
}

.jira-text-field::placeholder {
  @include typography-header(500, 12px, gray);
}

.jira-text-field:focus {
  background-color: #fff;
  border-color: #0052cc;
  outline: none;
  box-shadow: 0 0 0 0.1rem rgba(0, 82, 204, 0.25);
}

.no-selected-integration {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include typography-header(500, 16px, grey);
  width: 100%;
  height: 100%;
}

.no-selected-tenant {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include typography-header(500, 16px, grey);
  width: 100%;
  height: 100%;
}

