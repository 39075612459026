.content-locations {
  padding: 10px;

  .locationslabel {
    margin-left: 2px;
    margin-bottom: 6px;
    @include typography-header(200, 14px, black);

  }

  .autocomplete-container {
    margin-bottom: 10px;


    .MuiOutlinedInput-root {
      border: none;
      box-shadow: none;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .MuiInputBase-root {
      background: white;
      color: #2957a3;
      font-size: 13px;
    }

  }

  .reader-status-div {
    @include layout(row, flex-start);
    width: 100%;

    .autocomplete-container-reader {
      width: 70%;
      @extend .autocomplete-container;
      margin-bottom: 0px;
      @include typography-header(500, 13px, $primary-font-color);
    }

    .reader-status {
      width: 40%;
      @include layout(row, flex-end);

      .online-label {
        @include searchresultlabel(60px, 34px, #21b894, white);
      }

      .offline-label {
        @include searchresultlabel(60px, 34px, #EBC5C5, black);
      }

      .refresh-button {
       
        background-color: $button-bg-color-main;
        color: $button-font-color-main;
      }
    }



  }

}