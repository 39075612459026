@import "./variables";

@mixin layout($flexdirection, $justifycontent) {
  display: flex;
  flex-direction: $flexdirection;
  justify-content: $justifycontent;
  align-items: center;
}

@mixin parent-set-children-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin Appbar {
  background: $primary-background;
  position: static;
  height: $navbar-height;
  overflow: hidden;
}

@mixin Applynoborder {
  box-shadow: none;
  border: none;
}

@mixin typography-header($font-weight, $font-size, $font-color: "#000") {
  font-family: "Inter";
  font-weight: $font-weight;
  font-size: $font-size;
  color: $font-color;
}

@mixin button-styling($width, $height) {
  @include Applynoborder;
  @include typography-header(600, 14px, $primary-font-color);
  width: $width;
  height: $height;
  background-color: $button-bg-color-main;
  color: $button-font-color-main;
  text-transform: capitalize;
}

@mixin custom-pagination-style {
  .MuiPaginationItem-root {
    background-color: $secondary-background;
    font-size: 8px;
  }
  .MuiPaginationItem-root.Mui-selected {
    background-color: #2957a3;
    color: white;
  }
}

@mixin truncate-text($max-width) {
  max-width: $max-width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin subscription-status-label(
  $width,
  $height,
  $border-color,
  $background-color,
  $color
) {
  width: $width;
  height: $height;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: $height / 2;
  border: 2px solid $border-color;
  background-color: $background-color;
  color: $color;
  text-transform: capitalize;
}

@mixin override-button-mui {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  text-transform: none;
}

@mixin sm {
  @media only screen and (max-width: 640px) {
    @content;
  }
}
@mixin md {
  @media only screen and (max-width: 768px) {
    @content;
  }
}
@mixin lg {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}
@mixin xl {
  @media only screen and (max-width: 1280px) {
    @content;
  }
}
@mixin xxl {
  @media only screen and (max-width: 1536px) {
    @content;
  }
}

@mixin addverticalline($width: 90%, $height: 2px) {
  width: $width;
  background: #d9d9d9;
  height: $height;
}
