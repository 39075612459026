.card-wrapper {
  // margin-top: $margin-top-restpage - 5px;
  width: $payment-order-width;
  @include Applynoborder;
  position: relative;
  border-radius: 0px;

  .card-sub-section {
    height: $parent-card-title-height;
    padding: $payment-sub-card-padding;
    background-color: $secondary-background;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap:0.1rem;

    .card-title {
      color: $secondary-font-color;
      font-size: $reference-id-font-size;
      font-weight: 500;
      text-align: center;
      font-family: 'Poppins';
      margin-top: 2px;
    }
    .referenceId-text{
      width: 52%;
    }

    .description-text {
      width: 100%;
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .description {
    height: 120px;
  }

  .card-main-details {
    position: relative;

    .card-body {
      background-color: $tertiary-background;
      width: $payment-sub-card-width;
      height: $payment-sub-card-height;
      margin: 0 auto;
      margin-top: $difference-sub-main-top;
      position: relative;
      box-shadow: none;
      border: none;
      padding: $payment-sub-card-div-padding;

      .card-div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        gap: $order-details-gap;

        margin: 2px 0 $order-details-margin-bottom 10px;


        .card-div-content {
          color: $primary-font-color;
          font-size: $order-details-font-size;
          font-weight: 500;
          font-family: 'Poppins';

        }



      }
    }

    .cur {
      text-transform: uppercase;
    }

    .sub-div-card {
      position: absolute;
      background: #D4DDED;
      width: $order-last-card-width;
      height: $order-last-card-height;
      top: $order-last-card-top;
      left: $order-last-card-left;
      display: flex;
      border-radius: 3px;
      box-shadow: none;
      justify-content: center;
      align-items: center;

      .sub-div-content {
        color: $primary-font-color;
        font-size: $order-last-card-font-size;
        font-weight: 600;
        font-family: 'Poppins';
      }

      .total-sum {
        color: $secondary-font-color;
      }

      .sub-div-p {
        color: $secondary-font-color;
        font-size: $order-last-card-font-size;
        font-weight: 500;
        font-family: 'Poppins';
      }
    }


  }








}