@import "./mixins";
@import "./variables";
@import "./components/navbaradmin";
@import "./components/integrationonboarding";
@import "./components/pagelayout";
@import "./components/locationtable";
@import "./components/locationform";
@import "./components/readertable";
@import "./components/login";
@import "./components/tenantonboarding";
@import "./components/tenantable";
@import "./components/alertmodal";
@import "./components/tenantkeys";
@import "./components/savedmodal";
@import "./components/readercard";

body {
  margin: 0;
  padding: 0;
  background: #f8f8f9;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.App {
  min-height: 100vh;
}
