.Integration-card {
  .Integration-header {
    @include layout(row, space-between);
    .header-content {
      width: 85%;
      @include layout(row, flex-start);
      .error-message {
        @include typography-header(500, 11px, red);
        word-break: break-all;
      }
      align-items: stretch;
      .main {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      .MainText {
        @include typography-header(500, 14px, #333);
        height: 30px;
        display: flex;
        align-items: center;
      }
      .SubMainText {
        @include typography-header(500, 13px, rgba(31, 41, 55, 0.69));
        height: 30px;
        display: flex;
        align-items: center;
      }
    }
    .actions {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      width: 15%;
      gap: 5px;
      margin-top: -30px;
      .edit-integration-button {
        @include Applynoborder();
        background: #2957a3;
        width: 80px;
        height: 35px;
        text-transform: capitalize;
        @include typography-header(500, 12px, white);

        @include md {
          width: 160px;
          height: 35px;
          @include typography-header(500, 12px, white);
        }
        @include sm {
          width: 80px;
          margin-right: 10px;
        }
      }
      .save-integration-button {
        @include Applynoborder();
        background: #46c186;
        width: 80px;
        height: 35px;
        text-transform: capitalize;
        @include typography-header(500, 12px, white);
      }
      .save-integration-button:disabled {
        background-color: #ccc;
        color: #888;
      }
      .discard-integration-button {
        @include Applynoborder();
        background: #eba6a2;
        // border: 1px solid #dc362e;
        width: 80px;
        height: 35px;
        text-transform: capitalize;
        @include typography-header(500, 12px, white);
      }
    }
  }

  .payment-selector {
    margin-top: 10px;
    width: 100%;
    @include layout(column, flex-start);
    align-items: flex-start;
    gap: 10px;
    .payment-container {
      width: 100%;
      @include layout(row, space-between);
      align-items: flex-start;
      .braintree-config {
        @extend .stripe-config;
      }
      .stripe-config {
        width: 49%;
        padding: 8px;
        background: #efefef;
        border-radius: 5px;
        @include typography-header(500, 12px, black);
        .option-1 {
          margin-top: 8px;
          width: 100%;
          background: white;
          padding: 5px;
          border-radius: 6px;
          @include layout(row, flex-start);
          align-items: center;
        }
        .no-payment-message {
          @include typography-header(500, 12px, red);
        }
      }
    }
  }
  .line-divider {
    margin-top: 10px;
    @include addverticalline(100%, 1.7px);
  }
}
