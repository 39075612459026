.Tenant-keys {
  margin-top: -10px;
  .error-message {
    word-break: break-all;
    @include typography-header(500, 11px, red);
  }
  .callback-box {
    width: 100%;
    margin-bottom: 10px;
    border: 0.5px rgba(41, 87, 163, 0.2) solid;
    height: fit-content;
    padding: 5px;

    .header-text-apiadmin {
      @include typography-header(500, 14px, rgba(31, 41, 55, 0.69));
      margin-bottom: 5px;
    }

    .api-header-admin{
      display: flex;
      justify-content:flex-start;
      flex-direction:row;

      .api-refresh-button{
        display:flex;
        margin-left: auto;
        margin-top: 1px;
        width: 30px;
        height: 30px;
        background-color: $button-bg-color-main;
        @include typography-header(500, 14px, rgba(31, 41, 55, 0.69));
  
        .api-refresh-icon{
          width: 22px;
          height: 22px;
        }
      }

      .add-apicallbackurl-button {
        margin-top: -10px;
        color: $primary-font-color;
      }
      
    }
    

    
    .error-message {
      word-break: break-all;
      @include typography-header(500, 11px, red);
    }
    .one-entry-apiadmin {
      @include layout(row, flex-start);
      align-items: center;
      gap: 5px;
      .admin-key-value {
        width: 98%;
        @include typography-header(500, 12px, rgba(31, 41, 55, 0.69));
        .multiple-handle {
          word-break: break-all;
          margin-bottom: 5px;
        }
      }
      .error-message {
        word-break: break-all;
        @include typography-header(500, 11px, red);
      }
      .status {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-right: 8px;
        padding: 2px;
        .new-icon-success {
          width: 10px;
          height: 10px;
        }
      }
      .success {
        background-color: #8cc4a9;
      }
      .inactive {
        background-color: #f5f18e;
        color: darkgoldenrod;
      }
      .unsubsribed {
        background-color: #faccc6;
        color: rgb(250, 82, 82);
      }
      .api-data {
        width: 99%;
      }
    }
  }
  .admin-key-box {
    width: 100%;
    margin-bottom: 8px;
    height: fit-content;
    padding: 0px;
    .admin-key-header {
      margin-bottom: 8px;
      @include typography-header(400, 14px, black);
      .add-verification-auth-button,
      .add-verification-op-button {
        margin-top: -5px;
        color: $primary-font-color;
      }
    }
    .admin-key-entry {
      margin-bottom: 5px;
      @include layout(row, flex-start);
      align-items: flex-start;

      .admin-key-label {
        width: 20%;
        @include typography-header(500, 12px, rgba(31, 41, 55, 0.69));
      }

      .admin-key-value {
        width: 78%;
        word-break: break-all;
        @include typography-header(500, 12px, rgba(31, 41, 55, 0.69));
        .admin-key-typo {
          word-break: break-all;
          width: 100%;
          @include typography-header(500, 12px, rgba(31, 41, 55, 0.69));
        }

        .error-message {
          @include typography-header(500, 11px, red);
        }
      }
    }
    .admin-key-entry-new {
      margin-bottom: 5px;
      @include layout(row, flex-start);
      align-items: flex-start;

      .admin-key-label {
        width: 20%;
        @include typography-header(500, 12px, rgba(31, 41, 55, 0.69));
      }

      .display-keys-main{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        .display-key-value {
          word-break: break-all;
        }
      }
      
      .admin-key-value {
        padding-top: 0px;
        width: 78%;
        @include typography-header(500, 12px, rgba(31, 41, 55, 0.69));
        .error-message {
          word-break: break-all;
          @include typography-header(500, 11px, red);
        }
        .key-form-container {
          margin-top: -5px;
          padding-top: 0px;
          @include layout(row, flex-start);
          justify-content: flex-start;
          align-items: center;
          .editableconfig {
            padding-top: 0px;
            width: 100%;
            word-break: break-all;
          }
          .disabled-field {
            width: 100%;
            height: fit-content;
            word-break: break-all;
            padding: 5px;
            color: #172b4d;
            border-radius: 4px;
            @include Applynoborder();
            // border: 1px solid #dfe1e6;
            transition: border-color 0.15s ease-in-out,
              box-shadow 0.15s ease-in-out;
            @include typography-header(500, 13px, #172b4d);
            background: #efefef;
            margin-bottom: 10px;
          }

          .admin-key-newaction {
            margin-top: -5px;
            display: flex;
            padding-top: 0px;
            width: 50px;
          }
        }
      }
    }
  }
  .tenant-key-line {
    width: 100%;
    background: #d9d9d9;
    height: 1px;
    margin-top: 5px;
  }
}
